<template>

  <div class="card">

    <div class="card-header header-elements-inline" >
      <h5 class="card-title">Defect Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter Name here..." maxlength="100" v-if="defect" v-model="defect.name" >
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label font-weight-semibold">Process:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="defect" v-model="defect.process.code"  >
              <option v-for="process in processes" v-bind:value="process.code">
                {{ process.name }}
              </option>
            </select>
          </div>
        </div>

<!--        <div class="form-group row">-->
<!--          <label class="col-md-2 col-form-label font-weight-semibold">Print Name:</label>-->
<!--          <div class="col-md-10">-->
<!--            <input type="text" class="form-control" placeholder="Enter print name here..." maxlength="100" v-if="defect" v-model="defect.p_name">-->
<!--          </div>-->
<!--        </div>-->

      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
  import FabButton from '@/components/core/FabButton.vue'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'DefectForm',
    data () {
      return {
        defect: JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}'),
        processes: []
      }
    },
    props: {
      mydefect: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}')
      }
    },
    beforeMount () {
      this.defect = this.mydefect; // save props data to itself's data and deal with tms
    },
    component: {
      FabButton
    },
    created () {
      this.$data.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}')
    },
    mounted () {
      this.loadProcesses();
    },
    methods: {
      closeWindow() {
        this.$emit('defect_window_closed');
      },
      loadProcesses () {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.processes = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.processes = resp.data;
            }
          } else {
            swal ( { title: "Oops!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      saveDocument(){
        let self = this;

        self.$data.defect.process.code = parseInt(self.$data.defect.process.code);

        if(self.$data.defect.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.defect.process.code < 1){
          alert("Invalid Process");
          return
        }

        const requestOptions = {
          method:  (self.$data.defect.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.defect)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/defect/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, type:  "success"} );
            self.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}');
            self.$emit('defect_saved');
            self.closeWindow();

          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
