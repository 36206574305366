<template>
  <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Defect's List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="defect-table"
             class="table  table-hover table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
        <tr>
          <th data-field="id" data-class="d-none">id</th>
          <th data-field="code" data-width="100">Code</th>
          <th data-field="name">Name</th>
          <th data-field="process.name" >Process</th>
        </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">

        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="defect-window" transition="nice-modal-fade" :delay="100" :resizable="true" width="50%" height="50%" @before-open="beforeOpen" @before-close="beforeClose" >
      <DefectForm v-bind:mydefect="defect" v-on:defect_window_closed="closeModal" v-on:defect_saved="loadData" ></DefectForm>
    </vmodal>

  </div>

</template>

<script>
  import DefectForm from '@/components/v1/defect/DefectForm.vue'
  import FabButton from '@/components/core/FabButton.vue'
  import moment from 'moment-timezone'
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'DefectView',
    components: {
      DefectForm,
      FabButton
    },
    data () {
      return {
        mytable: {},
        processes: [],
        defect: JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}')
      }
    },
    created () {

    },
    mounted () {
      let self = this;

      self.$data.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}');

      this.$data.mytable = $('#defect-table');

      this.$data.mytable.bootstrapTable();
      $('[data-toggle="popover"]').popover();

      this.$data.mytable.contextmenu({
        target: '#context-menu',
        scopes: 'tbody > tr',
        onItem: function (row, e) {
          var id = $(row.children('*')[0]).text();

          if ($(e.target).text() === 'Refresh') {
            self.loadData();
          }else if ($(e.target).text() === 'Modify') {
            self.modifyDocument (id);
          } else if ($(e.target).text() === 'Remove') {
            self.removeDocument(id);
          }
        }
      });

      $('#txtsearch').keyup(function () {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
        $('#defect-table>tbody>tr').show().filter(function () {
          var text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
          return !~text.indexOf(val)
        }).hide()
      });

      self.loadData();
    },
    methods: {

      closeModal () {
        this.$modal.hide('defect-window');
        this.$data.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}');
      },
      showModal () {
        this.$modal.show('defect-window');
      },
      beforeOpen(){
        console.log('beforeOpen');
      },
      beforeClose(){
        this.$data.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}');
      },
      loadData () {
        let self = this;
        this.$modal.hide('defect-window');

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.mytable.bootstrapTable('load',[]);

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/defect/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != null){
              self.$data.mytable.bootstrapTable('load', resp.data);
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      },
      modifyDocument (id) {
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        self.$data.defect = JSON.parse('{"id":"","code":0,"type":0,"name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":""}}');

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/defect/${id}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.defect = resp.data;
            self.$modal.show('defect-window');
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
        });
      },
      removeDocument (id) {
        let self = this;

        self.$data.defect.id = id;

        const requestOptions = {
          method: 'DELETE',
          mode:'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.defect)
        };

        swal({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          timer: 3000,
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete this!'
        }).then((result) => {
          if (result.value) {
            fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/defect/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
              self.$modal.hide('defect-window')
              self.loadData();
              swal(
                'Deleted!',
                'Your request has been processed',
                'success'
              );
            }).catch(function (err) {
              swal({ title: "Oops", text: err.toString(), type: "error" });
            });
          }
        });
      }
    }
  }
</script>

<style scoped>
  .modal {
    text-align: center;
    padding: 0!important;
  }

  .modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }

  .modal-dialog {
    display: inline-block;
    text-align: left;
    width: 40%;
    vertical-align: middle;
  }
</style>
